<template>
<div id="main" v-if="this.courselist" class="page-wrapper" style="display:none">
    <Navigation />
    <div id="videoFile" v-if="is_video==true" class="fixed top-0 bg-black bg-opacity-90 w-full h-full flex items-center" style="z-index:1000">
        <div v-if="this.videoLink" class="w-full h-full relative-md mx-auto max-w-sm md:max-w-2xl max-h-56 md:max-h-96">
            <iframe class="w-full h-full mx-auto rounded" :src="this.videoLink" title="YouTube video player" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen> </iframe>
            <img src="https://img.icons8.com/cotton/46/undefined/delete-sign--v2.png" @click="is_video=false" class="absolute top-2 right-2 md:-top-14 md:-right-14 z-50 cursor-pointer" />
        </div>
        <div v-else class="w-full h-full relative mx-auto max-w-sm md:max-w-2xl max-h-56 md:max-h-96">
            <h1 class="text-center text-xl card py-4">No Video found</h1>
            <img @click="is_video=false" class="absolute -top-8 right-4 md:-top-14 md:-right-14 z-50 cursor-pointer h-6 w-6 " src="../assets/closebutton.svg"  />
            <!-- <img src="https://img.icons8.com/cotton/54/undefined/delete-sign--v2.png" @click="is_video=false" class="absolute -top-8 right-4 md:-top-14 md:-right-14 z-50 cursor-pointer" /> -->
        </div>
    </div>
    <div v-if="is_content==true" class="fixed top-0 bg-black bg-opacity-90 w-full h-full flex items-center" style="z-index:1000">
        <div v-if="this.contant" class="popupdisplay w-full h-full relative mx-auto max-w-sm md:max-w-3xl bg-white overflow-y-auto " style="height:94%;scrollbar-color:gray white">
            <img src="https://img.icons8.com/cotton/46/undefined/delete-sign--v2.png" @click="is_content=false" class="sticky top-0 right-0 float-right cursor-pointer" style="z-index:1000;" />
            <v-md-preview :text="this.contant"></v-md-preview>

            <!-- <div v-if="is_topbutton" @click="topFunction()" class="fixed bottom-4 right-20 xl:right-72 gradient-btn">
                top
            </div> -->
        </div>
        <div v-else class="w-full h-full relative mx-auto max-w-sm md:max-w-2xl max-h-56 md:max-h-96">
            <h1>No Data found</h1>
            <img src="https://img.icons8.com/cotton/54/undefined/delete-sign--v2.png" @click="is_video=false" class="absolute -top-8 right-4 md:top-6 md:right-10 z-50 cursor-pointer" />
        </div>
    </div>
    <div class="section video-hero wf-section">
        <div class="container-default-1209px w-container">
            <div class="video-hero-wrapper">
                <div v-if="this.courseDetailInfo" data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8ab3" style="" class="split-content video-hero-left">
                    <h1 class="title video-hero">{{this.courselist[0].title}}</h1>
                    <!-- <p class="paragraph video-hero">{{this.courselist[0].short_description}}</p> -->
                    <!-- <p class="button-primary w-button" >Info</p> -->
                    <router-link :to="{name:'studentEvents',params: {slug: this.userInfo.slug_id,course_id: this.courselist[0].id,instructor_id: this.courseDetailInfo.instructor_id,course_name: this.courselist[0].title,}}" class="button-primary w-button">Course Dashboard</router-link>
                </div>
                <div data-w-id="63cfe198-7374-b53a-7ec2-349f89f8f8ac" style="" class="course-preview video-hero">
                    <img v-if="this.courselist[0].thumbnail" :src="this.courselist[0].thumbnail" alt="Blog Post Icon - Academy Webflow Template" class="image" style="width:500px;height:300px">
                    <img v-else src="../../catalyft-ui-templates/images/image-videos-course-academy-template.svg" alt="Blog Post Icon - Academy Webflow Template" class="image">
                    
                </div>
            </div>
        </div>
    </div>
    <div class="section videos wf-section">
        <!-- <div class="container-fw video-card-details w-container">
            <div data-w-id="24541658-8c67-a0b0-835c-748585847b28" style="" class="animation-wrapper">
                <div class="card videos-details">
                    <div class="spacer videos-details"></div>
                    <div class="course-detail-wrapper"><img src="../../catalyft-ui-templates/images/icon-course-detail-01-academy-template.svg" alt="Play Icon - Academy Webflow Template" class="videos-detail-icon">
                        <div class="videos-detail-text">Videos: {{this.lessonCount}}</div>
                        <div class="videos-detail-text strong"></div>
                    </div>
                    <div class="spacer videos-details"></div>
                    <div class="course-detail-wrapper"><img src="../../catalyft-ui-templates/images/icon-event-02-academy-template.svg" alt="Clock Icon - Academy Webflow Template" class="videos-detail-icon">
                        <div class="videos-detail-text">Learning Hours: </div>
                        <div class="videos-detail-text strong">{{this.courseDetailInfo.total_duration}}</div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="container-fw video-tabs w-container">
            <div data-duration-in="300" data-duration-out="100" data-current="Tab 1" data-easing="ease" class="videos-tabs w-tabs">
                <div id="Course" class="videos-tabs-menu w-tab-menu ">
                    <a @click="tap='about'" data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8ad7" style="" :class="{'w--current': tap=='about'}" class="videos-tab-link w-inline-block w-tab-link ">
                        <div>About</div>
                    </a>
                    <a @click="tap='video'" data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8ada" style="" :class="{'w--current': tap=='video'}" class="videos-tab-link w-inline-block w-tab-link">

                        <div>Learning Path</div>
                    </a>
                    <a @click="tap='resources'" data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8add" style="" :class="{'w--current': tap=='resources'}" class="videos-tab-link w-inline-block w-tab-link">
                        <div>Resources</div>
                    </a>
                    <a @click="tap='Ttable'" data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8ae0" style="" :class="{'w--current': tap=='Ttable'}" class="videos-tab-link w-inline-block w-tab-link">
                        <div>Timetable</div>
                    </a>
                    <!-- <a @click="tap='community'" data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8ae0" style="" :class="{'w--current': tap=='community'}" class="videos-tab-link w-inline-block w-tab-link">
                        <div>Community</div>
                    </a> -->
                </div>
                <div data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8ae3" style="" class="videos-tabs-content w-tab-content max-w-4xl mx-auto">
                    <div v-if="tap=='about'" class="  ">
                        <h2>About the Course</h2>
                        <div class="rich-text w-richtext">
                            <v-md-preview :text="this.courselist[0].description"></v-md-preview>
                        </div>
                    </div>
                    <div v-if="tap=='video' && sectionInfo!= null" class="">
                        <div v-for="section in sectionInfo.data" :key="section">
                            <div class=" videos">
                                <h3 class="title videos">{{section.title}}</h3>
                            </div>
                            <div class="spacer course-videos"></div>
                            <div v-if="Object.keys(this.lessonInfo) != 'error'" class="videos-wrapper">
                                <div v-for="lesson in lessonInfo.results" :key="lesson">
                                    <div v-if="section.id == lesson.section_id">
                                        <a href="#" class="border border-gray-400 flex justify-between rounded p-4">
                                            <div @click="videoLink=lesson.video_url;is_video=true" class="flex items-center gap-2 justify-center">
                                                <img src="../../catalyft-ui-templates/images/icon-course-video-academy-template.svg" alt="Play Icon" class="course-video-icon mb-0">
                                                <div class="h-full flex items-center">
                                                    <h3 class="text-base md:text-xl course-video h-full hover:text-blue-800 m-0 flex items-center">{{lesson.title}}</h3>
                                                </div>
                                            </div>
                                            <div class="course-video-duration-text flex gap-4 items-center"> 
                                                <a v-if="lesson.video_url" :href="lesson.video_url" target="_blank">
                                                <font-awesome-icon  class="text-base text-indigo-900 hover:text-indigo-600 self-center h-8 w-8 text-2xl" :icon="['fas','video']" />
                                                </a>
                                                <a v-if="lesson.pdf_file" :href="lesson.pdf_file" target="_blank" download="resource">
                                                <font-awesome-icon  class="text-base text-indigo-900 hover:text-indigo-600 self-center h-8 w-8 text-2xl" :icon="['fas','download']" />
                                                </a>
                                                <!-- <button class="gradient-btn" >view Notes</button> <button>{{lesson.duration}} min</button>  -->
                                                <font-awesome-icon @click="contant=lesson.content;is_content=true" class="text-base text-indigo-900 hover:text-indigo-600 self-center h-8 w-8 text-2xl" :icon="['fas','book']" />
                                                </div>
                                        </a>
                                        <div class="spacer course-videos"></div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="divider videos"></div> -->
                        </div>
                    </div>
                    <div v-if="tap=='resources'" class="">
                        <div class="top-content files">
                            <!-- <h3 class="title files">{{documentList.length}} Files &amp; Resources</h3> -->
                            <!-- <p class="paragraph mg-bottom-0px">Browse all the links and downloads mentioned in the course. If you would like us to include any other resource, please let us know in the Community group.</p> -->
                        </div>
                        <div v-if="documentList.length>0">

                            <div class="files-wrapper" v-for="file in documentList" :key="file">
                                <div class="card course-files w-inline-block" >
                                    <div class="split-content course-files-left cursor-pointer" @click="pdf_val=file.resource_file,pdf_popup=true">
                                        <div class="icon-files-wrapper"><img src="../../catalyft-ui-templates/images/icons-files-04-academy-template.svg" alt="File - Academy Webflow Template"></div>
                                        <div class="title files-resources hover:text-indigo-600 cursor-pointer">{{file.name}}</div>
                                    </div>
                                    <div v-if="file.download" class="download-files-text"> 
                                        <a :href="file.resource_file" target="_blank" download> Download   <span class="icon-file"></span></a> 
                                        </div>
                                        <div v-else class="download-files-text" target="_blank" @click="pdf_val=file.resource_file,pdf_popup=true">
                                         View   <img src="../assets/eye.svg">
                                        </div>

                                </div>
                                <div class="spacer files"></div>
                            </div>
                        </div>
                        <div v-else class="card empty-state w-dyn-empty">
                            <div class="empty-state-text">There are no Resources available yet.</div>
                        </div>
                    </div>
                    <!-- Time Table -->
                    <div v-if="tap=='Ttable'" class="">
                        <div class="top-content files">
                            <h3 class="title files">Timetable</h3>
                            <p class="paragraph mg-bottom-0px">Find you time table here</p>
                        </div>
                        <div v-if="timeTable != ''">

                            <div class="files-wrapper">
                                <div class="card course-files w-inline-block" >
                                    <div class="split-content course-files-left cursor-pointer" @click="pdf_val=timeTable_val,pdf_popup=true">
                                        <div class="icon-files-wrapper"><img src="../../catalyft-ui-templates/images/icons-files-04-academy-template.svg" alt="File - Academy Webflow Template"></div>
                                        <div class="title files-resources hover:text-indigo-600 cursor-pointer">{{timeTable.resource_file.split('/media/timetable/')[1]}}</div>
                                    </div>
                                    <div v-if="timeTable.download" class="download-files-text"> 
                                        <a :href="timeTable_val" target="_blank" download> Download   <span class="icon-file"></span></a> 
                                        </div>
                                        <div v-else class="download-files-text" target="_blank" @click="pdf_val=timeTable_val,pdf_popup=true">
                                         View   <img src="../assets/eye.svg">
                                        </div>

                                </div>

                            </div>
                        </div>
                        <div v-else class="card empty-state w-dyn-empty">
                            <div class="empty-state-text">There are no Time Table available yet.</div>
                        </div>
                    </div>

                    <div v-if="tap=='community'" class=" ">
                        <h3 class="title videos-community">Join our community</h3>
                        <div class="videos-social-media-wrapper">
                            
                            <div class="spacer videos-community"></div>
                            <a href="https://twitter.com/JainOnlineEdu" class="card videos-community w-inline-block">
                                <div class="split-content videos-community-left">
                                    <div class="icon-videos-community-wrapper"><img src="../../catalyft-ui-templates/images/icon-videos-community-02-academy-template.svg" alt="Twitter Icon - Academy Webflow Template"></div>
                                    <div>
                                        <h3 class="title videos-community-card">Twitter</h3>
                                        <p class="paragraph mg-bottom-0px">Lorem ipsum dolor sit amet, consectere adipiscing elit. Feugiat feugiat congue viverra facilisis.</p>
                                    </div>
                                </div>
                                <div class="button-secondary small">
                                    <div>Join Now!</div>
                                </div>
                            </a>
                            <div class="spacer videos-community"></div>
                            <a href="https://www.linkedin.com/company/jain-online/" class="card videos-community w-inline-block">
                                <div class="split-content videos-community-left">
                                    <div class="icon-videos-community-wrapper"><img src="../../catalyft-ui-templates/images/icon-videos-community-03-academy-template.svg" alt="LinkedIn Icon - Academy Webflow Template"></div>
                                    <div>
                                        <h3 class="title videos-community-card">LinkedIn Group</h3>
                                        <p class="paragraph mg-bottom-0px">Lorem ipsum dolor sit amet, consectere adipiscing elit. Feugiat feugiat congue viverra facilisis.</p>
                                    </div>
                                </div>
                                <div class="button-secondary small">
                                    <div>Join Now!</div>
                                </div>
                            </a>
                            <div class="spacer videos-community"></div>
                            
                            <a href="https://api.whatsapp.com/send?phone=917777080832&text=Hi!%20I%27d%20like%20to%20know%20more%20details.&utm_source=google&utm_medium=search_cpc&utm_campaign=Reprise_Search_Bangalore_Brand&utm_keyword=online%20jain&device=c&utm_matchtype=p&utm_network=g&utm_placement=&utm_Adposition=&utm_location=9062009&utm_Sitelink=%7D&utm_Audience=kwd-1450894747364&utm_campaignid=17148294400&utm_adgroupid=136061083373&adgroup=Online_Brand_Phrase&gclid=Cj0KCQjwhY-aBhCUARIsALNIC04NPslASZbaI92G1_ZZWBo3Wm420s1IwxuLRfeR2SP967Sly75FQTYaAiVHEALw_wcB" class="card videos-community w-inline-block">
                                <div class="split-content videos-community-left">
                                    <div class="icon-videos-community-wrapper"><img src="../../catalyft-ui-templates/images/icon-videos-community-05-academy-template.svg" alt="Whatsapp Icon - Academy Webflow Template"></div>
                                    <div>
                                        <h3 class="title videos-community-card">WhatsApp Group</h3>
                                        <p class="paragraph mg-bottom-0px">Lorem ipsum dolor sit amet, consectere adipiscing elit. Feugiat feugiat congue viverra facilisis.</p>
                                    </div>
                                </div>
                                <div class="button-secondary small">
                                    <div>Join Now!</div>
                                </div>
                            </a>
                            <div class="spacer videos-community"></div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-default-1209px w-container">
        <div data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8bfa" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="divider"></div>
    </div>
    <!-- <div data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8bfb" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="section related-courses wf-section">
        <div class="container-default-1209px w-container">
            <div data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8bfd" style="" class="top-content more-courses">
                <div class="split-content courses-section-left">
                    <h2 class="title more-courses">Keep Learning!</h2>
                </div>
                <router-link :to="{name:'Courses'}" class="button-secondary w-button">All Courses</router-link>
            </div>
            <div class="w-dyn-list">
                <div v-if="this.courselimited" role="list" class="courses-section-grid w-dyn-items">
                    <div v-for="course in this.courselimited" :key="course" data-w-id="a5b44c17-4d56-228c-6603-cc1b7f9e3168" style="" role="listitem" class="course-card-wrapper w-dyn-item">
                        <div @click="forwardCouse(course)" class="card w-80 md:w-96" style="height:528px">
                            <a href="#" class="card-image-link w-inline-block"><img :src="course.thumbnail" alt="" class="image course w-80 md:w-96" style="overflow: hidden;height:208px"></a>
                            <div class="card-content">
                                <a href="#" class="card-title-link w-inline-block">
                                    <h3 class="text-lg 2xl:text-xl course">{{course.title.slice(0,80)}} <span v-if="course.title.length>80">....</span> </h3>
                                </a>
                                <p class="text-base">{{course.short_description.slice(0,80)}} <span v-if="course.short_description.length>80">....</span></p>
                                <div class="divider course-card"></div>
                                <div class="course-card-details-wrapper">
                                    <div class="level-wrapper">
                                        <img v-if="course.level=='beginner'" src="../../catalyft-ui-templates/images/icon-level-01-academy-template.svg" alt="Beginners - Academy Webflow Template" class="level-icon">
                                        <img v-else-if="course.level=='Intermediate'" src="../../catalyft-ui-templates/images/icon-level-02-academy-template.svg" alt="Beginners - Academy Webflow Template" class="level-icon">
                                        <img v-else src="../../catalyft-ui-templates/images/icon-level-03-academy-template.svg" alt="Beginners - Academy Webflow Template" class="level-icon">
                                        <div class="w-dyn-list">
                                            <div role="list" class="levels-list w-dyn-items">
                                                <div role="listitem" class="level-text-wrapper w-dyn-item">
                                                    <a href="#" class="level-text">{{course.level}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="course-card-price">&#8377;{{course.price}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="card empty-state w-dyn-empty">
                    <div class="empty-state-text">There are no courses available yet.</div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="container-default-1209px w-container">
        <div data-w-id="fee87d54-5c1d-9d5c-67e3-157fa7bb8c04" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="divider"></div>
    </div>

    <!-- Pdf Preview -->
    <div v-if="pdf_popup" @scroll="checkScroll" class="popupdisplay fixed top-0 bg-black bg-opacity-70 w-full h-screen left-0 flex justify-center overflow-y-auto" style="z-index:6001">
        <div class="w-full h-full flex justify-center relative max-w-4xl">
            <template v-if="pdf_val.includes('pdf')">
            <vue-pdf-embed :source="prefixUpdate(pdf_val)" class="w-full" />
            </template>
            <template v-else >
                <div class="h-20 w-52 border bg-white shadow-lg flex justify-center items-center rounded my-10">
                    Is Not a PDF File
                </div>
            </template>
            <img src="https://img.icons8.com/material-outlined/24/000000/delete-sign.png" @click="pdf_popup=false" class="absolute top-2 right-2 z-50 cursor-pointer" />
        </div>
        <div v-if="is_topbutton" @click="topFunction()" class="fixed bottom-4 right-20 xl:right-72 gradient-btn">
            top
        </div>
    </div>
    <Footer />
</div>
<div id="main2" class="w-full h-screen flex items-center justify-center ">
    <img src="../assets/LoadingBall.svg" alt="" class="w-32 h-32">
</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue"
import axios from "axios";
import '@uivjs/vue-markdown-preview/markdown.css';
import MarkdownPreview from '@uivjs/vue-markdown-preview';
import VuePdfEmbed from 'vue-pdf-embed'
import {
    mapGetters
} from "vuex";
export default {
    components: {
        Navigation,
        MarkdownPreview,
        Footer,
        VuePdfEmbed
    },
    data: function () {
        return {
            lessonInfo: "",
            sectionInfo: "",
            courselist: "",
            courseDetailInfo: "",
            lessonCount: "",
            tap: 'about',
            videoLink: '',
            is_video: false,
            is_content: false,
            content: '',
            courselimited: '',
            documentList: '',
            courselistsuggest: '',
            is_topbutton: false,
            pdf_popup: false,
            pdf_val: '',
            timeTable:'',
            timeTable_val:'',
            host:process.env.VUE_APP_API,

        }
    },
    props: ["courseid"],
    computed: mapGetters(["userInfo", "subscriptionInfo", "isAuthenticated"]),
    async created() {
        await axios.get(`${process.env.VUE_APP_API}/timetableresource/${this.courseid}/?Key=${this.userInfo.token_key}&format=json`)
            .then(response => {
                if (response.data.status != 400){
                    
                    console.log("response",response.data)
                    if (response.data["error"]){
                        this.timeTable = ''
                        this.timeTable_val = ''
                    }
                    else{
                        this.timeTable = response.data.data
                        this.timeTable_val = response.data.data.resource_file
                    }
                }
                else {
                    this.timeTable = ''
                    this.timeTable_val = ''
                    }
                })
                
            

        await axios.get(`${process.env.VUE_APP_API}/courses/?id=${this.courseid}&format=json`)
            .then(response => {
                this.courselist = response.data.data
            })

        if (this.courselist[0].course_free) {
            this.detailsgets()
            this.documentInfo()
        } else if (this.subscriptionInfo != "Nothing to show") {
            var subscribe_list = []
            this.subscriptionInfo.forEach(val => {
                subscribe_list.push(val.course[0])
            })
            if (subscribe_list.includes(parseInt(this.courseid))) {
                this.detailsgets()
                this.documentInfo()
            }
        } else {
            // confirm('You dont have a access')
            this.$router.push({
                name: 'PageNotFound',
            })
        }

    },

    mounted() {
        setTimeout(() => {
            document.querySelector("#main").style.display = "block"
            document.querySelector("#main2").style.display = "none"
        }, 3000)

        document.addEventListener("keydown", (e) => {

            if (e.keyCode == 27) {
                this.is_video = false
                this.is_content = false
            }

        })

    },

    methods: {
        prefixUpdate(url) {
            if (process.env.NODE_ENV === 'production') {
                return url.replace('http://', 'https://')
            } else {
                return url
            }
        },
        checkScroll() {
            if (document.querySelector('.popupdisplay').scrollTop > 20) {
                this.is_topbutton = true
            } else {
                this.is_topbutton = false
            }
        },
        topFunction() {
            console.log(document.querySelector('.popupdisplay').scrollTop = 0)
        },

        async detailsgets() {
            await axios.get(`${process.env.VUE_APP_API}/lessons/?course_name=${this.courseid}`)
                .then(response => {
                    this.lessonInfo = response.data
                    if (Object.keys(this.lessonInfo) == "error") {
                        this.lessonCount = 0
                    } else {
                        this.lessonCount = this.lessonInfo.count
                    }
                });
            await axios.get(`${process.env.VUE_APP_API}/section/?course_name=${this.courseid}`)
                .then(response => {
                    this.sectionInfo = response.data
                })
            await axios.get(`${process.env.VUE_APP_API}/coursesdata/${this.courseid}/`)
                .then(response => {
                    this.courseDetailInfo = response.data
                })
            await axios.get(`${process.env.VUE_APP_API}/courses/?is_published=true&format=json`)
                .then(response => {
                    this.courselistsuggest = response.data.data
                    if (this.courselistsuggest.length >= 3) {

                        this.courselimited = this.courselistsuggest.slice(0, 3)
                    } else {
                        this.courselimited = response.data.data
                    }
                })
        },

        async documentInfo() {
            await axios.get(`${process.env.VUE_APP_API}/coures-resource/list/?Course=${this.courseid}`)
                .then(response => {
                    if (response.data.message == 'Have a Documents')
                        this.documentList = response.data.data
                    else {
                        this.documentList = ''
                    }
                })
        },
        forwardCouse(course) {
            this.$router.push({
                name: 'CouseDetail',
                params: {
                    slug: course.slug,
                    title: course.title
                }
            })
        },
    }

}
</script>

<style scoped>

</style>

<template>
<div>
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <confirmPopup v-if='popuplessonDelete==true' :title="titlepass" @cancelClick="popuplessonDelete=false" @confirmClick="deleteLesson(idpass)" />
    <confirmPopup v-if='popupsectionDelete==true' :title="titlepass" @cancelClick="popupsectionDelete=false" @confirmClick="deleteSection(idpass)" />
    <div class="min-h-screen relative">
        <!-- <div class="flex">
        <textarea  v-model="markdown" name="" id="" rows="10" @input="update"></textarea>
        <markdown-preview :source="markdown"></markdown-preview>
  </div> -->
        <!-- {{this.lessonIn fo}} -->
        <div class="relative w-full md:w-full lg:w-4/6  2xl:w-1/2">
            <h1 class="font-bold text-2xl text-gray-700 p-4 w-full md:w-5/6 xl:w-1/2">Section & Lesson</h1>
            <div @click="$emit('backToCourse')" class="absolute top-6 right-0 border cursor-pointer flex rounded-lg bg-white">
                <h1 class="px-1 md:px-4 py-1 text-sm md:text-base rounded hover:bg-teal-500 hover:text-white">Back</h1>
                <!-- <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" /> -->
            </div>
        </div>

        <div v-if="sectionInfo.count > 0" class="border rounded-t-md rounded-b-md w-full lg:w-4/6 2xl:w-1/2 overflow-hidden mb-4 m-4">
            <div v-for="section in sectionInfo.data" :key="section">
                <div class="flex items-center gap-2 py-2 border-b bg-gray-100 w-full px-3">
                    <h1 class="text-md md:text-xl text-gray-700">{{section.title}}</h1>
                    <img @click="sectionval=section;popUpsectionUpdate=true" title="Edit" class="h-5 w-5 md:h-6 md:w-6 cursor-pointer" src="../assets/edit.svg" alt="">
                    <img @click="titlepass=section.title;popupsectionDelete=true;idpass=section.id" class="h-5 w-5 md:h-6 md:w-6 cursor-pointer" src="../assets/trash.svg" alt="">
                </div>
                <div v-if="lessonInfo != null">
                    <div v-for="lesson in lessonInfo.results" :key="lesson" class="text-gray-600">
                        <h1 v-if="section.id == lesson.section_id" class="text-xs md:text-lg border-b py-2 hover:bg-gray-100 pl-3 md:pl-8">
                            {{lesson.title}}
                            <span @click="titlepass=lesson.title;popuplessonDelete=true;idpass=lesson.id" class="mx-2 text-gray-500 bg-white border border-gray-400 hover:bg-red-600 hover:border-red-600 hover:text-white p-0.5 px-1 md:px-2 inline-block float-right text-xs rounded cursor-pointer"> Delete</span>
                            <span @click="lessonVal=lesson;popUp=true" class="mx-2 text-teal-600 bg-white border border-teal-600 hover:bg-teal-600 hover:text-white p-0.5 px-1 md:px-2 inline-block float-right text-xs rounded cursor-pointer">Edit</span>
                        </h1>
                    </div>
                </div>
                <h1 v-else class="text-md border-b py-2 hover:bg-gray-100 pl-8 ">
                    No lesson added
                </h1>
            </div>
        </div>
        <div v-else class="px-4">
            <h1 class="text-md  py-2">
                No section added yet </h1>
        </div>

        <button @click="popUpsection=true" class="gradient-btn ml-4">
            Add section
        </button>
        <button v-if="sectionInfo.count > 0" @click="popUpLesson=true" class="gradient-btn ml-2 ">
            Add Lesson
        </button>
        <button v-if="sectionInfo.count > 0" @click="popupquiz=true" class="gradient-btn ml-2 ">
            Add Quiz
        </button>

        <!-- Add new Section -->
        <div v-if="popUpsection" class="w-full h-full mx-auto absolute top-0 bg-black bg-opacity-75 z-40 p-4 ">
            <form ref="addSection" @submit.prevent="createNewsection" class="w-full 2xl:w-4/6 mx-auto bg-gray-100 relative p-6 flex flex-col gap-4 rounded-md">
                <div @click="popUpsection=false" class="absolute top-6 right-6 border cursor-pointer flex rounded-lg bg-white">
                    <h1 class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close</h1>
                    <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
                </div>
                <h1 class="text-xl mt-5 ">Add New Section</h1>
                <div class="w-5/6 flex flex-col gap-2">
                    <label for="title">
                        Title
                    </label>
                    <input id="title" type="text" class="border border-gray-300 rounded-md w-full p-2">
                </div>
                <button type="submit" class="gradient-btn mt-4 w-44">
                    Add
                </button>
            </form>
        </div>
        <!-- Update Sections -->
        <div v-if="popUpsectionUpdate" class="w-full h-full mx-auto absolute top-0 bg-black bg-opacity-75 z-50 p-4 ">
            <form @submit.prevent="updatesection(sectionval)" class="w-full 2xl:w-4/6 mx-auto bg-gray-100 relative p-6 flex flex-col gap-4 rounded-md">
                <div @click="popUpsectionUpdate=false" class="absolute top-6 right-6 border cursor-pointer flex rounded-lg bg-white">
                    <h1 class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close</h1>
                    <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
                </div>
                <h1 class="text-xl py-4">Update Section</h1>
                <div class="w-5/6 flex flex-col gap-2">
                    <label for="title">
                        Title
                    </label>
                    <input id="title" v-model="sectionval.title" type="text" class="border border-gray-300 rounded-md w-full p-2">
                </div>
                <button type="submit" class="gradient-btn mt-4 w-44">
                    Update
                </button>

            </form>
        </div>

        <!-- add new lesson -->
        <div v-if="popUpLesson" class="w-full mx-auto absolute top-0 bg-black bg-opacity-75 z-50 p-4 ">
            <!-- Unit name -->
            <div class="w-5/6 mx-auto">
                <form ref="lessonaddFn" @submit.prevent="createNewLesson" class="relative bg-gray-100 flex flex-col gap-4 w-full 2xl:w-5/6 p-6 rounded-md">
                    <div @click="popUpLesson=false" class="absolute top-6 hover:bg-gray-100 right-6 border cursor-pointer flex bg-white rounded-lg">
                        <h1 class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close</h1>
                        <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
                    </div>
                    <h1 class='font-semibold text-base md:text-lg 2xl:text-2xl'>Add New Lesson</h1>
                    <div class="">
                        <label for="section">
                            Section
                        </label>
                        <select name="section" class="bg-gray-50 border-gray-300 rounded-md p-2 block w-full" id="section">
                            <option v-for="section in sectionInfo.data" :key="section" :value="section.id">
                                {{section.title}}
                            </option>
                        </select>

                    </div>

                    <!-- Title -->
                    <div class="">
                        <label for="title">
                            Title <span class="text-red-400">*</span>
                        </label>
                        <input id="title" type="text" class="border border-gray-300 rounded-md w-full p-2">
                    </div>

                    <div class="flex gap-2">
                        <!-- duration -->
                        <div class="w-1/3">
                            <label for="duration">
                                Duration <span class="text-red-400">*</span>
                                <br> <small class="text-gray-500">(Enter minutes only like 5 or 10 or 35 )</small>
                            </label>
                            <input id="duration" type="text" class="border border-gray-300 rounded-md w-full p-2" >
                        </div>

                        <!-- Video Url -->
                        <div class="w-1/3">
                            <label for="videoUrl">
                                video Url
                                <br> <small class="text-gray-500">Source url of the video</small>
                            </label>
                            <input id="videoUrl" type="text" class="border border-gray-300 rounded-md w-full p-2">
                        </div>
                    </div>

                    <!-- Content -->
                    <div class="">
                        <div class="col-start-1 col-end-4">
                            <label for="content">
                                Content <span class="text-red-400">*</span>
                            </label>
                            <MarkdownEditor class=" rounded-md" />
                        </div>
                    </div>
                    <!--File Upload  -->
                    <div class=" flex gap-6 items-center">
                        <div class=" flex flex-col gap-2 ">
                            <label for="source">
                                <span class="">File Upload <small class="text-gray-500">(.pdf files only)</small> </span>
                            </label>
                            <input id="source" type="file" class="border border-gray-300 rounded-md  p-2 bg-white">
                        </div>
                        <div class="flex items-center gap-2 justify-center">
                            <label for="downloadable">
                                <span class="">Downloadable</span>
                            </label>
                            <input id="downloadable" type="checkbox" class="border border-gray-300 rounded-md  p-2">
                        </div>
                    </div>

                    <!-- keywords -->
                    <div class="">
                        <label for="keywords">
                            Keywords
                            <br><small class="text-gray-500">comma ( , ) separated values</small>
                        </label>
                        <textarea id="keywords" maxlength="200" class="border border-gray-300 rounded-md w-full p-2" placeholder="keyword1, keyword2, keyword3, ..."></textarea>
                    </div>
                    <button type="submit" class="gradient-btn w-44">
                        Add
                    </button>
                </form>
            </div>
        </div>

        <!-- Update lesson -->
        <div v-if="popUp" class="w-full absolute top-0 bg-black bg-opacity-75 z-50 p-4">
            <form @submit.prevent="updateLesson" class="relative w-full lg:w-4/6 bg-gray-100 mx-auto p-6 rounded-md flex flex-col gap-4">
                <div @click="popUp=false" class="mb-1 absolute top-6 right-6  border cursor-pointer hover:bg-gray-100 flex rounded-lg bg-white">
                    <h1 class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close</h1>
                    <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
                </div>
                <h1 class="text-base md:text-xl 2xl:text-2xl">Update Lesson</h1>
                <div class="flex flex-col gap-2">
                    <label for="Title">Title <span class="text-red-400">*</span></label>
                    <input type="text" v-model="lessonVal.title" class="border border-gray-300 rounded-md w-1/2">
                </div>
                <div class="flex flex-col gap-2">
                    <label for="duration">Duration <span class="text-red-400">*</span></label>
                    <input type="text" v-model="lessonVal.duration" class="border border-gray-300 rounded-md w-1/2">
                </div>
                <div class="flex flex-col gap-2">
                    <label for="video_url">Video_url</label>
                    <input type="text" v-model="lessonVal.video_url" class="border border-gray-300 rounded-md w-1/2">
                </div>
                <div class="flex flex-col gap-2 w-full">
                    <label for="description">Content <span class="text-red-400">*</span></label>
                    <MarkdownEditor class="rounded-md w-full bg-red-200" :markvalues="lessonVal.content" />
                </div>
                <div class="flex flex-col gap-2">
                    <label for="keyworeds">keyworeds</label>
                    <input type="text" v-model="lessonVal.keyworeds" class="border border-gray-300 rounded-md w-1/2">
                </div>
                <div class="flex items-center gap-4 ">
                    <label for="keyworeds">Status</label>
                    <input type="checkbox" v-model="lessonVal.status" class="border border-gray-300 rounded-md  px-2">
                </div>

                <button class="gradient-btn inline rounded cursor-pointer w-44">Update</button>
            </form>
        </div>
    </div>
     <div v-if="popupquiz" class="absolute top-0 bg-black bg-opacity-75 w-full" >
            <Quiz :course_id="this.lesson_id" />
        </div>
</div>
</template>

<script>
import MarkdownPreview from '@uivjs/vue-markdown-preview';
import '@uivjs/vue-markdown-preview/markdown.css';
import Quiz from "@/components/randomQuiz.vue"
import axios from "axios";
// import MarkdownEditor from "@/components/MarkdownEditor.vue"
import MarkdownEditor from "@/components/MarkdownEditor2.vue"
import {
    mapGetters
} from "vuex"
import FlashMessage from "@/components/flashMessage.vue";
import confirmPopup from "@/components/confirmPopup.vue";

export default ({
    props: ['courseId'],
    emits: ["backToCourse"],
    computed: mapGetters(["userInfo"]),
    data() {
        return {
            markdown: "# Hello",
            lessonInfo: "",
            lessonVal: "",
            popUp: false,
            popUpLesson: false,
            sectionInfo: "",
            popUpsection: false,
            sectionval: "",
            popUpsectionUpdate: false,
            messages: {
                text: "",
                type: 'null',
            },
            popupsectionDelete: false,
            idpass: "",
            popuplessonDelete: false,
            titlepass: "",
            lesson_id: "",
            popupquiz: false,

        }
    },
    components: {
        MarkdownPreview,
        MarkdownEditor,
        FlashMessage,
        confirmPopup,
        Quiz
    },
    mounted() {
        this.lessoninfoGet()
        this.sectionInfoGet()

    },
    methods: {

        async lessoninfoGet() {
            let couseId = this.courseId
            await axios.get(`${process.env.VUE_APP_API}/lessons/?course_name=${couseId}`)
                .then(response => {

                    if (Object.keys(response.data) == 'error') {

                        this.lessonInfo = null
                    } else {

                        this.lessonInfo = response.data

                    }

                });
        },

        async sectionInfoGet() {
            let couseId = this.courseId
            await axios.get(`${process.env.VUE_APP_API}/section/?course_name=${couseId}`)
                .then(response => {
                    this.sectionInfo = response.data
                })
        },

        async updateLesson(el) {

            let token_key = this.userInfo.token_key
            let infoDetails = {
                "id": this.lessonVal.id,
                "title": this.lessonVal.title,
                "duration": this.lessonVal.duration,
                "video_url": this.lessonVal.video_url,
                "status": this.lessonVal.status,
                "created_at": this.lessonVal.created_at,
                "updated_at": new Date(),
                "content": el.target.elements[3].value,
                "keyworeds": this.lessonVal.keyworeds,
                "slug": this.lessonVal.slug,
                "section_id": this.lessonVal.section_id,
                "course_name": this.lessonVal.course_name,
            }
            this.popUp = false;

            await axios.put(`${process.env.VUE_APP_API}/lessonupdate/${this.lessonVal.id}/?Key=${token_key}`, infoDetails)
                .then(response => {
                    if (response.data.message == "lesson updated successfully") {
                        this.messages = {
                            text: "lesson updated successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: "Event Faild",
                            type: 'error',
                        }
                    }
                })

            await this.lessoninfoGet()

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3000)

        },
        async createNewLesson(el) {

            var sourceFile
            if (el.target.elements.source.files.length > 0) {

                sourceFile = el.target.elements.source.files[0]
            } else {
                sourceFile = ""
            }

            let urls = `${process.env.VUE_APP_API}/lessonsnew/?Key=${this.userInfo.token_key}`;
            let HeaderVal = new FormData();
            let couseId = this.courseId

            HeaderVal.append("title", el.target.elements.title.value)
            HeaderVal.append("duration", el.target.elements.duration.value)
            HeaderVal.append("video_url", el.target.elements.videoUrl.value)
            HeaderVal.append("status", true)
            HeaderVal.append("content", el.target.elements[4].value)
            HeaderVal.append("keyworeds", el.target.elements.keywords.value)
            HeaderVal.append("section_id", el.target.elements.section.value)
            HeaderVal.append("course_name", couseId)
            HeaderVal.append("download", el.target.elements.downloadable.checked)
            HeaderVal.append("pdf_file", sourceFile)
            // for (var value of HeaderVal.values()) {
            //     console.log(value);
            //     }

            await axios.post(urls, HeaderVal, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {
                
                if (response.data.message == "your lesson added successfully") {
                    this.messages = {
                        text: "Lesson added successfully",
                        type: 'success',
                    }
                    this.$refs.lessonaddFn.reset()
                    this.popUpLesson = false
                } else {
                    this.messages = {
                        text: "Event Faild",
                        type: 'error',
                    }
                }
            })
            
            await this.lessoninfoGet()
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3000)

        },
        async createNewsection(el) {
            let newsection = {
                "title": el.target.elements.title.value,
                "course_id": this.courseId
            }
            await axios.post(`${process.env.VUE_APP_API}/sectionnew/?Key=${this.userInfo.token_key}`, newsection)
                .then(response => {
                    if (response.data.message == "your section added successfully") {
                        this.messages = {
                            text: "Section added successfully",
                            type: 'success',
                        }
                        this.$refs.addSection.reset()
                        this.popUpsection = false
                    } else {
                        this.messages = {
                            text: "Event Faild",
                            type: 'error',
                        }
                    }
                })
            await this.sectionInfoGet()
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3000)
            // setTimeout(()=>{this.$router.go(0);},1000)

        },
        updatesection(data) {
            this.popUpsectionUpdate = false

            let updatedsection = {
                "id": data.id,
                "title": data.title,
                "course_name": data.course_name
            }

            axios.put(`${process.env.VUE_APP_API}/Sectionupdate/${data.id}/?Key=${this.userInfo.token_key}`, updatedsection)
                .then(response => {
                    if (response.data.message == "section updated successfully") {
                        this.messages = {
                            text: "section updated successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: "Event Faild",
                            type: 'error',
                        }
                    }
                })
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3000)

        },
        async deleteLesson(lessonVal) {
            await axios.delete(`${process.env.VUE_APP_API}/lesson_deleted/${lessonVal}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    //  setTimeout(()=>{this.$router.go(0);},1000)
                    if (response.data.message == "This Lesson data deleted successfully") {
                        this.messages = {
                            text: "lesson Deleted successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: "Event Faild",
                            type: 'error',
                        }
                    }
                })
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3000)

            this.popuplessonDelete = false;
            await this.lessoninfoGet()

        },
        async deleteSection(section) {

            await axios.delete(`${process.env.VUE_APP_API}/section_deleted/${section}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    if (response.data.message == "This Section data deleted successfully") {
                        this.messages = {
                            text: "Section Deleted successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: "Event Faild",
                            type: 'error',
                        }
                    }
                })
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3000)

            this.popupsectionDelete = false
            await this.sectionInfoGet()
            //  setTimeout(()=>{this.$router.go(0);},1000)

        }
    },

});
</script>

<style>

</style>
